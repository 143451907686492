import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "wir-sichern-dass-ihnen-das-wasser-nicht-bis-zum-kopf-steht"
    }}>{`Wir sichern, dass Ihnen das Wasser nicht bis zum Kopf steht.`}</h1>
    <h4 {...{
      "id": "wartung"
    }}>{`Wartung`}</h4>
    <ul>
      <li parentName="ul">{`Wartung von eigen- und fremderstellten Öl-, Gas-, Dampf-, Solar- und Wärmepumpenanlagen`}</li>
      <li parentName="ul">{`Wartung von Lüftungs- und Klimaanlagen`}</li>
      <li parentName="ul">{`Einmessen von Lüftungsanlagen und Absaugungsanlagen`}</li>
      <li parentName="ul">{`Legionellenbekämpfung in Trinkwasseranlagen`}</li>
    </ul>
    <h4 {...{
      "id": "störungen"
    }}>{`Störungen`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Störungen melden Sie bitte während der Bürozeiten unter 034385-51287. Wir nehmen diese auf und beheben diese noch am gleichen Tag.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Außerhalb der Bürozeiten erreichen Sie unter 034385-51287 einen Anrufbeantworter, auf dem Sie die für diesen Tag gültige Funk-Nummer unseres Kundendienstmonteures erhalten. Dieser wird sich dann sofort um eine Störungsbehebung bemühen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Unser Not- und Bereitschaftsdienst ist damit 24 Stunden am Tag und auch an Sonn- und Feiertagen für Sie da! Unsere Wartungskunden werden bevorzugt bearbeitet.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Wir sind Ihr kompetenter Ansprechpartner bei:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Reparaturen an haustechnischen Anlagen gleich welcher Art`}</li>
          <li parentName="ul">{`Heizungsausfall`}</li>
          <li parentName="ul">{`Rohrbruch`}</li>
          <li parentName="ul">{`Rohrverstopfung`}</li>
          <li parentName="ul">{`usw.`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`FORDERN SIE UNS!`}</strong></p>
    <p><img alt="Traum" src={require(".//images/uploads/traum.svg")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      